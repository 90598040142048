import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Container, Row } from 'react-bootstrap'
import dynamic from 'next/dynamic'

import styles from './style.module.scss'
import useWindowSize from '@shared/hooks/windowSize'
import IplRetentions from '@shared/components/widgets/iplRetentions'
// import { scoreCardSliderLoader } from '@shared/libs/allLoader'
// import ScorecardSlider from '@shared/components/scorecardSlider'
// import OnMouseAndScroll from '@shared/components/lazyLoad/onMouseAndScroll'
// import { REACT_APP_ENV } from '@shared/constants'

// const Layout = dynamic(() => import('@shared-components/layout'))
// const ScorecardSlider = dynamic(() => import('@shared-components/scorecardSlider'), { loading: () => scoreCardSliderLoader() })
const HomeNav = dynamic(() => import('@shared-components/homeNav'))
const ScorecardSlider = dynamic(() => import('@shared/components/scorecardSlider'))
const Ads = dynamic(() => import('@shared/components/ads'))
const AllWidget = dynamic(() => import('@shared/components/allWidget'), { ssr: false })
const HomePageStories = dynamic(() => import('@shared/components/stories/homepage-stories'))
// const PromotionSmall = dynamic(() => import('@shared/components/adsPromotion/promotionSmall'), { ssr: false })
// const PromotionFull = dynamic(() => import('@shared/components/adsPromotion/promotionFull'), { ssr: false })
// const SeriesPointTable = dynamic(() => import('@shared/components/widgets/seriesPointTable'), { ssr: false })
// const SeriesKeyStats = dynamic(() => import('@shared/components/widgets/SeriesKeyStats'))

function HomePageContent({ articles, children, widgetPosition, miniScorecard, scorecardHeader, stories, widgetData }) {
  const [width] = useWindowSize()
  const [activeTab, setActiveTab] = useState('ar')

  return (
    <>
      <div style={{ height: `${width > 440 ? '259px' : '245px'}` }}>
        <ScorecardSlider
          data={miniScorecard}
          scorecardHeader={scorecardHeader}
          isSeriesTitle
          adData={{
            id: 'div-ad-gpt-23148549803-1728887854-0',
            adIdDesktop: 'Crictracker_New_Desktop_HP_ATF_320x168',
            dimensionDesktop: [320, 168],
            slotId: '23148549803'
          }}
        />
      </div>

      {/* <Container className='d-md-none'>
        <SeriesKeyStats
          data={widgetData?.seriesKeyStats}
          iSeriesId="649ac0872979adc89b9606f0"
          mostSixes={{ id: '6230302c358523ee1d2692ce', URL: '/cwc-cricket-world-cup/stats/batting-most-sixes/' }}
          mostRuns={{ id: '62302fc0358523ee1d264d15', URL: '/cwc-cricket-world-cup/stats/batting-most-runs/' }}
          // mostFifties={{ id: '62303026358523ee1d2690a7', URL: '/cwc-cricket-world-cup/stats/batting-most-run50/' }}
          mostCenturies={{ id: '6230301e358523ee1d268da4', URL: '/cwc-cricket-world-cup/stats/batting-most-centuries/' }}
          mostWickets={{ id: '6230303b358523ee1d269831', URL: '/cwc-cricket-world-cup/stats/bowling-top-wicket-takers/' }}
          statsURL="/cwc-cricket-world-cup/stats/"
          title="WC 2023"
          CapSectionTitle="Top Players"
        />
      </Container> */}
      <Container className="d-none d-md-block mt-2" style={{ minHeight: '100px' }}>
        {width > 767 && ( // Desktop top
          <Ads
            id="div-ad-gpt-23148549803-1728887854-1"
            adIdDesktop="Crictracker_New_Desktop_HP_ATF_728x90"
            dimensionDesktop={[728, 90]}
            slotId="23148549803"
            className={'text-center bg-transparent'}
          />
        )}
      </Container>
      <div className="common-section">
        <Container className={styles.homeContainer}>
          <Row>
            <Col lg={3} className={`${styles.commonSidebar} order-1 order-lg-0 common-sidebar bt-width d-none d-lg-block`}>
              {width > 991 && (
                <>
                  {/* <PromotionSmall isHomePagePromotion /> */}
                  <IplRetentions isSmall />
                  <AllWidget show={true} position="l" widgetPosition={widgetPosition} />
                  {/* <LazyLoad>
                    <PixFuture />
                  </LazyLoad> */}
                  <Ads
                    id="div-ad-gpt-23148549803-1728887854-3"
                    adIdDesktop="Crictracker_New_Desktop_HP_LHS_300x600"
                    dimensionDesktop={[300, 600]}
                    slotId="23148549803"
                    className="sticky-ads position-sticky mb-2"
                  />
                </>
              )}
            </Col>
            <Col lg={6} className={styles.centerContent}>
              <HomeNav active={activeTab} handleChange={(t) => setActiveTab(t)} />
              {children(activeTab)}
            </Col>
            <Col lg={3} className={`${styles.commonSidebar} common-sidebar bt-width d-none d-lg-block`}>
              {/* {width > 991 && (
                <> */}
              {/* <SeriesKeyStats
                data={widgetData?.seriesKeyStats}
                iSeriesId="649ac0872979adc89b9606f0"
                mostSixes={{ id: '6230302c358523ee1d2692ce', URL: '/cwc-cricket-world-cup/stats/batting-most-sixes/' }}
                mostRuns={{ id: '62302fc0358523ee1d264d15', URL: '/cwc-cricket-world-cup/stats/batting-most-runs/' }}
                // mostFifties={{ id: '62303026358523ee1d2690a7', URL: '/cwc-cricket-world-cup/stats/batting-most-run50/' }}
                mostCenturies={{ id: '6230301e358523ee1d268da4', URL: '/cwc-cricket-world-cup/stats/batting-most-centuries/' }}
                mostWickets={{ id: '6230303b358523ee1d269831', URL: '/cwc-cricket-world-cup/stats/bowling-top-wicket-takers/' }}
                statsURL="/cwc-cricket-world-cup/stats/"
                title="WC 23"
                CapSectionTitle="Top Players"
              /> */}
              {/* <SeriesPointTable id="649ac0872979adc89b9606f0" title="WC POINTS TABLE" pageUrl="/cricket-world-cup-points-table/" /> */}
              {/*                   <OnMouseAndScroll>
                  </OnMouseAndScroll> */}
              <AllWidget show={true} position="r" widgetPosition={widgetPosition} />

              {/* </>
              )} */}
            </Col>
          </Row>
        </Container>
      </div>
      <HomePageStories stories={stories} />
    </>
  )
}
HomePageContent.propTypes = {
  children: PropTypes.func,
  articles: PropTypes.array,
  widgetPosition: PropTypes.array,
  miniScorecard: PropTypes.array,
  scorecardHeader: PropTypes.array,
  stories: PropTypes.array,
  widgetData: PropTypes.object
}
export default HomePageContent
