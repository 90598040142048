import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import bannerDesktop from '@assets/images/ipl/IPL-auction-2025-d.jpg'
import bannerMobile from '@assets/images/ipl/IPL-auction-2025-m.jpg'
import MyImage from '@shared/components/myImage'
import CustomLink from '@shared/components/customLink'
import useTimer from '@shared/hooks/useTimer'
import { addLeadingZeros } from '@shared/utils'
import styles from './style.module.scss'

function IplRetentions({ className, isSmall }) {
  const targetDate = '2024-11-24T13:00:00'
  const { timer } = useTimer(targetDate)
  const [isMobile, setIsMobile] = useState(isSmall)

  useEffect(() => {
    if (!isSmall) {
      // Handle mobile detection
      const handleResize = () => {
        setIsMobile(window.innerWidth < 768)
      }
      handleResize()
      // Add event listener
      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
    }
  }, [])

  const timerText = (() => {
    const today = new Date()
    const target = new Date(targetDate)

    today.setHours(0, 0, 0, 0)
    target.setHours(0, 0, 0, 0)

    const diffTime = target - today
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

    if (diffDays >= 3) {
      return `${diffDays} Days to go`
    } else if (timer?.days > 0 || timer?.hours > 0 || timer?.min > 0) {
      return `${addLeadingZeros(timer?.hours + (timer?.days * 24))} : ${addLeadingZeros(timer?.min)} : ${addLeadingZeros(timer?.sec)}`
    } else {
      return null
    }
  })()

  return (
    <div className={`${className} mt-3 mb-3 text-center`}>
      <CustomLink
        href="https://www.crictracker.com/tag/ipl-auction/"
        className="d-block position-relative mx-auto"
        style={{ width: '100%', maxWidth: '728px' }}>
        <div className={`position-relative ${styles.imageContainer} ${isMobile ? styles.small : ''} mx-auto`} >
          <MyImage
            src={isMobile ? bannerMobile : bannerDesktop}
            alt="IPL Auction"
            width={isMobile ? '320' : '728'}
            height={isMobile ? '150' : '360'}
            priority={true}
            className="w-100"
          />
          <div
            suppressHydrationWarning
            className={`${styles.timerText} position-absolute font-semi ${isMobile ? 'bottom-0 end-0 mb-2' : 'big-text top-50 end-0 translate-middle-y'}`}>
            {timerText}
          </div>
        </div>
      </CustomLink>
    </div>
  )
}
IplRetentions.propTypes = {
  isSmall: PropTypes.string,
  className: PropTypes.string
}
export default IplRetentions
